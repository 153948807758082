import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Menu from '../../components/Menu';
import Notifications from '../../components/Notifications';
import { StatusBadge } from '../../components/StatusBadge';
import Typography from '../../components/Typography';
import { EAccountRole } from '../../redux/Auth/saga';
import { TGlobalState } from '../../redux/types';
import { colors } from '../../utils/colors';

const logo = `${process.env.PUBLIC_URL}/images/logos/figc.png`;
const golee = `${process.env.PUBLIC_URL}/images/logos/horizontal_white.png`;

const Style = styled.div`
	background-color: ${colors['primary']};

	display: grid;
	grid-template-columns: 50px 250px;

	.sider {
		border-right: 1px solid #444;
		position: relative;
		z-index: 999;
		background-color: ${colors['dark_grey']};
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		padding-bottom: 10px;

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100px;
			img {
				height: 45px;
			}
		}

		.notifications,
		.toggle,
		.logout {
			text-align: center;
			margin-top: 10px;
			cursor: pointer;
			i {
				font-size: 24px;
				color: #eee;
			}
		}
	}

	.sidebar {
		position: relative;
		box-shadow: 5px 0px 10px 0px rgb(0 0 0 / 20%);
		.profile {
			height: 100px;
			padding: 0 10px;
			display: flex;
			align-items: center;
			img {
				border-radius: 50%;
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}

			h4,
			p {
				margin: 0;
				color: #fff;
			}

			p {
				margin-top: 3px;
			}
		}

		.powered-by {
			text-decoration: none;
			width: 100%;
			position: absolute;
			bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			img {
				width: 60px;
			}
		}
	}
`;

type TProps = {
	children?: React.ReactNode;
};

const Sider = ({ children, ...props }: TProps) => {
	const { logout } = useAuth0();

	const { messages, language } = useSelector((state: TGlobalState) => state.Locale);
	const { regions, provinces } = useSelector((state: TGlobalState) => state.Locations);
	const { profile } = useSelector((state: TGlobalState) => state.Auth);
	const delegation = useSelector((store: TGlobalState) => store.Delegations.current);

	const toggleSider = () => {
		document.getElementById('application-wrapper')?.classList.toggle('sider-closed');
	};

	const toggleNotifications = () => {
		document.getElementById('notifications-menu')?.classList.toggle('hidden');
	};

	return (
		<Style>
			<div className="sider">
				<div>
					<div className="logo">
						<img src={logo} />
					</div>

					<Notifications.Toggle onClick={toggleNotifications} />
				</div>

				<div>
					{/* <div
						className="toggle"
						onClick={() => toggleSider()}
						data-tip={messages['toggle_menu']}
						data-effect="solid">
						<i className="las la-bars"></i>
					</div> */}

					<div
						className="logout"
						onClick={() => logout({ returnTo: window.location.origin })}
						data-tip={messages['logout']}
						data-effect="solid">
						<i className="las la-sign-out-alt"></i>
					</div>
				</div>
			</div>

			<div className="sidebar">
				{profile && (
					<div className="profile">
						<img
							src={
								delegation?.logo ||
								profile.picture ||
								'https://golee.it/assets/img/golee-logo-red.png'
							}
						/>
						<div>
							<Typography.Text level={2}>
								{messages[profile.role?.role]}
							</Typography.Text>

							{delegation && (
								<Typography.Text level={2}>
									{messages['delegation']}{' '}
									{delegation.type === 'PRO'
										? provinces[delegation.province!][language]
										: regions[delegation.region!][language]}
								</Typography.Text>
							)}

							{/* <StatusBadge /> */}
						</div>
					</div>
				)}

				<Menu />

				<a className="powered-by" href="https://golee.it" target="_blank">
					<Typography.Text level={3} style={{ color: '#ddd', margin: 0 }}>
						powered by
					</Typography.Text>
					<img src={golee} />
				</a>
			</div>

			<Notifications.Menu id="notifications-menu" onToggle={toggleNotifications} />
		</Style>
	);
};

export default Sider;
