import React from 'react';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

type TProps = {
	type?: 'filled' | 'outline' | 'link';
	color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'default';
	disabled?: boolean;
	children?: React.ReactNode;
	onClick?: (ev: any) => void;
	style?: React.CSSProperties;
};

const Element = styled.button`
	border-radius: 8px;
	padding: 8px 20px;
	appearence: none;
	border: 1px solid;
	box-shadow: none;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: bold;
	justify-content: center;
	font-family: 'Inter', sans-serif;

	&:not(:disabled) {
		cursor: pointer;
	}

	&.filled {
		&.default {
			border-color: ${colors['default']};
			background-color: ${colors['default']};
			color: ${colors['white']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['default_dark']};
					background-color: ${colors['default_dark']};
				}

				&:active {
					border-color: ${colors['default_dark_1']};
					background-color: ${colors['default_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['default_light']};
				background-color: ${colors['default_light']};
			}
		}

		&.primary {
			border-color: ${colors['primary']};
			background-color: ${colors['primary']};
			color: ${colors['white']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['primary_dark']};
					background-color: ${colors['primary_dark']};
				}

				&:active {
					border-color: ${colors['primary_dark_1']};
					background-color: ${colors['primary_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['primary_light_4']};
				background-color: ${colors['primary_light_4']};
			}
		}

		&.success {
			border-color: ${colors['success']};
			background-color: ${colors['success']};
			color: ${colors['white']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['success_dark']};
					background-color: ${colors['success_dark']};
				}

				&:active {
					border-color: ${colors['success_dark_1']};
					background-color: ${colors['success_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['success_light']};
				background-color: ${colors['success_light']};
			}
		}

		&.danger {
			border-color: ${colors['danger']};
			background-color: ${colors['danger']};
			color: ${colors['white']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['danger_dark']};
					background-color: ${colors['danger_dark']};
				}

				&:active {
					border-color: ${colors['danger_dark_1']};
					background-color: ${colors['danger_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['danger_light']};
				background-color: ${colors['danger_light']};
			}
		}

		&.warning {
			border-color: ${colors['warning']};
			background-color: ${colors['warning']};
			color: ${colors['white']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['warning_dark']};
					background-color: ${colors['warning_dark']};
				}

				&:active {
					border-color: ${colors['warning_dark_1']};
					background-color: ${colors['warning_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['warning_light']};
				background-color: ${colors['warning_light']};
			}
		}
	}

	&.outline {
		&.default {
			border-color: ${colors['default']};
			background-color: ${colors['white']};
			color: ${colors['default']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['default_dark']};
					background-color: ${colors['default_dark']};
					color: ${colors['white']};
				}

				&:active {
					border-color: ${colors['default_dark_1']};
					background-color: ${colors['default_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['default_light']};
				background-color: ${colors['default_light']};
			}
		}

		&.primary {
			border-color: ${colors['primary']};
			background-color: ${colors['white']};
			color: ${colors['primary']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['primary_dark']};
					background-color: ${colors['primary_dark']};
					color: ${colors['white']};
				}

				&:active {
					border-color: ${colors['primary_dark_1']};
					background-color: ${colors['primary_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['primary_light_4']};
				background-color: ${colors['primary_light_4']};
			}
		}

		&.success {
			border-color: ${colors['success']};
			background-color: ${colors['white']};
			color: ${colors['success']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['success_dark']};
					background-color: ${colors['success_dark']};
					color: ${colors['white']};
				}

				&:active {
					border-color: ${colors['success_dark_1']};
					background-color: ${colors['success_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['success_light']};
				background-color: ${colors['success_light']};
			}
		}

		&.danger {
			border-color: ${colors['danger']};
			background-color: ${colors['white']};
			color: ${colors['danger']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['danger_dark']};
					background-color: ${colors['danger_dark']};
					color: ${colors['white']};
				}

				&:active {
					border-color: ${colors['danger_dark_1']};
					background-color: ${colors['danger_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['danger_light']};
				background-color: ${colors['danger_light']};
			}
		}

		&.warning {
			border-color: ${colors['warning']};
			background-color: ${colors['white']};
			color: ${colors['warning']};

			&:not(:disabled) {
				&:hover {
					border-color: ${colors['warning_dark']};
					background-color: ${colors['warning_dark']};
					color: ${colors['white']};
				}

				&:active {
					border-color: ${colors['warning_dark_1']};
					background-color: ${colors['warning_dark_1']};
				}
			}

			&:disabled {
				border-color: ${colors['warning_light']};
				background-color: ${colors['warning_light']};
			}
		}
	}

	&.link  {
		padding: 0;
		background: none;
		border: none;

		&:hover {
			background: none !important;
			border: none !important;
		}
		&:active {
			background: none !important;
			border: none !important;
		}

		&.default {
			color: ${colors['default']};

			&:disabled {
				color: ${colors['default_light']};
				background: none !important;
				border: none !important;
			}
		}

		&.success {
			color: ${colors['success']};

			&:disabled {
				color: ${colors['success_light']};
				background: none !important;
				border: none !important;
			}
		}

		&.danger {
			color: ${colors['danger']};

			&:disabled {
				color: ${colors['danger_light']};
				background: none !important;
				border: none !important;
			}
		}

		&.primary {
			color: ${colors['primary']};

			&:disabled {
				color: ${colors['primary_light_4']};
				background: none !important;
				border: none !important;
			}
		}
	}
`;

const Button: React.FC<TProps> = ({ type = 'filled', color = 'primary', style = {}, ...props }) => {
	return (
		<Element
			style={style}
			className={type + ' ' + color}
			disabled={props.disabled}
			onClick={!props.disabled && props.onClick ? props.onClick : () => {}}>
			{props.children}
		</Element>
	);
};

export default Button;
