import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
	$loadNotifications,
	$newNotification,
	$notificationsConnectionClosed,
	$notificationsConnectionOpened,
	$readAllNotifications,
} from '../../redux/Notifications/middleware';
import { TGlobalState } from '../../redux/types';
import { colors } from '../../utils/colors';
import io from 'socket.io-client';
import Typography from '../Typography';
import List from './List';
import { HermesService } from '../../services/Hermes';

const Wrapper = styled.div`
	background: ${colors['dark_grey']};
	border-right: 1px solid ${colors['figc_gold']};
	height: 100vh;
	z-index: 998;
	width: 400px;
	position: absolute;
	top: 0;
	left: 50px;

	&.hidden {
		transform: translateX(-400px);
	}

	.header {
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: ${colors['light_grey']};
		padding: 0 10px;
		* {
			margin: 0;
		}

		i {
			font-size: 24px;
			cursor: pointer;
		}
	}

	.list {
		height: calc(100vh - 80px);
	}
`;

type TProps = {
	children?: React.ReactNode;
	id?: string;
	onToggle?: () => any;
};

const Menu = ({ children, ...props }: TProps) => {
	const dispatch = useDispatch();

	const { messages } = useSelector((state: TGlobalState) => state.Locale);
	const { profile, access_token } = useSelector((state: TGlobalState) => state.Auth);

	const { connectionOpened, data, unread } = useSelector(
		(state: TGlobalState) => state.Notifications
	);

	let socket: SocketIOClient.Socket;

	const openSocket = () => {
		if (connectionOpened) {
			return;
		}

		const socket_uri = `https://${
			new URL(process.env.REACT_APP_API_URL_HERMES as string).hostname
		}`;

		const socket_path = `${
			new URL(process.env.REACT_APP_API_URL_HERMES as string).pathname
		}socket.io`;

		socket = io(socket_uri, {
			reconnectionDelayMax: 10000,
			path: socket_path,
			transportOptions: {
				polling: {
					extraHeaders: {
						Authorization: access_token,
					},
				},
			},
		});

		socket.on('connect_error', (err: any) => {
			console.log(`socket notifications: error`, err);
		});

		socket.on('new_notification', (data: any) => {
			console.log(`socket notifications: new_notification`);

			dispatch($newNotification(data));

			// dispatch($loadFriendlyMatchRequests(true));
			// dispatch($loadMatchVariationRequests(true));
			// dispatch($loadMatchReports(true));
		});

		socket.on('connect', () => {
			console.log(`socket notifications: Connesso come ${socket.id}`);
			dispatch($notificationsConnectionOpened());
		});

		socket.on('disconnect', () => {
			console.log(`socket notifications: disconnesso.`);
			dispatch($notificationsConnectionClosed());
		});
	};

	const closeSocket = () => {
		if (socket) {
			socket.close();
		}
	};

	useEffect(() => {
		HermesService.setup(profile.sub);
		dispatch($loadNotifications(profile.sub, 0, 20));
		openSocket();

		return () => {
			closeSocket();
		};
	}, []);

	return (
		<Wrapper className="hidden" id={props.id}>
			<div className="header">
				<div>
					<Typography.Title level={2}>
						{messages['notices_and_notifications']}
					</Typography.Title>

					<Typography.Text
						level={2}
						className="link gold"
						onClick={() => dispatch($readAllNotifications(profile.sub))}>
						{messages['mark_all_as_read']}
					</Typography.Text>
				</div>
				<i className="las la-times" onClick={props.onToggle}></i>
			</div>

			<div className="list">
				<List />
			</div>
		</Wrapper>
	);
};

export default Menu;
