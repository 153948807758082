import mixpanel from 'mixpanel-browser';

class MixPanel {
	public static init() {
        console.log('[mixpanel] init');
		mixpanel.init(
			<string>process.env.REACT_APP_MIXPANEL_API_KEY,
			{ api_host: 'https://api-eu.mixpanel.com' },
			''
		);
	}

	public static trackEvent(name: string, payload?: any) {
		try {
			console.log(`[mixpanel] tracking ${name}`);
			mixpanel.track(name, payload);
		} catch (err) {
			console.error(err);
		}
	}
}

export default MixPanel;
